import React from 'react'

import Layout from '../components/Layout'
import { HeroSimpleCentered } from '../components/Global/Hero'
import { YellowButton, BlackButton } from '../components/Global/Button'
import ContactForm from '../components/BookNow/ContactForm'
import SEO from '../components/Global/SEO'
import { useStaticQuery, graphql } from 'gatsby'

const BookNowPage = ({ location }) => {

  const { seoImg } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "chauffeur-me-van-at-titanic-belfast.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
    }
  `)
  
  return (
    <Layout>

      <SEO
        title="Book Now Executive &amp; Luxury Chauffeured Vehicles"
        description="Our friendly and knowledgeable staff are available to answer any questions about our services or pricing and to help you with any booking enquiries."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <HeroSimpleCentered>
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          Book Now with
          <br className="xl:hidden" />
          <span className="text-yellow-300"> Chauffeur Me</span>
        </h2>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Our friendly and knowledgeable staff are available to answer any questions about our services or pricing and to help you with any booking enquiries. Our booking centre is available 24/7 throughout the year.
        </p>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <YellowButton to="/services" className="flex items-center justify-center rounded-sm shadow sm:mr-2 mb-4 sm:mb-0">Our Services</YellowButton>
          <BlackButton to="/tours" className="flex items-center justify-center rounded-sm shadow sm:ml-2">View Tours</BlackButton>
        </div>
      </HeroSimpleCentered>

      <div className="py-12 overflow-hidden xl:pb-24">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <svg className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
              <defs>
                <pattern id="svg-pattern-squares-1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#svg-pattern-squares-1)" />
            </svg>

            <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                  Get In Touch
                </h4>
                <p className="mt-3 text-lg leading-7 text-gray-500">
                  For more information on any of our tours or book with us please contact us.
                </p>

                <ul className="mt-10">
                  <li>
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                          <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8L10.8906 13.2604C11.5624 13.7083 12.4376 13.7083 13.1094 13.2604L21 8M5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19Z" />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-semibold text-gray-900">Email Us</h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          <a href="mailto:" className="text-yellow-400 underline hover:text-yellow-300 hover:no-underline ease-in-out transition-all duration-50">hello@chauffeurmeltd.com</a>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                          <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5C3 3.89543 3.89543 3 5 3H8.27924C8.70967 3 9.09181 3.27543 9.22792 3.68377L10.7257 8.17721C10.8831 8.64932 10.6694 9.16531 10.2243 9.38787L7.96701 10.5165C9.06925 12.9612 11.0388 14.9308 13.4835 16.033L14.6121 13.7757C14.8347 13.3306 15.3507 13.1169 15.8228 13.2743L20.3162 14.7721C20.7246 14.9082 21 15.2903 21 15.7208V19C21 20.1046 20.1046 21 19 21H18C9.71573 21 3 14.2843 3 6V5Z" />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-semibold text-gray-900">Call Us</h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          <a href="tel:447444710931" className="text-yellow-400 underline hover:text-yellow-300 hover:no-underline ease-in-out transition-all duration-50">+44 (0)7444 710931</a>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                          <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.2322 5.23223L18.7677 8.76777M16.7322 3.73223C17.7085 2.75592 19.2914 2.75592 20.2677 3.73223C21.244 4.70854 21.244 6.29146 20.2677 7.26777L6.5 21.0355H3V17.4644L16.7322 3.73223Z" />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-semibold text-gray-900">Write to Us</h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          Chauffeur Me Ltd<br/>
                          Unit 1, Glenville Industrial Estate<br/>
                          Glenville Road<br/>
                          Newtownabbey<br/>
                          BT37 0TU
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <ContactForm />

            </div>
          </div>
        </div>
    </Layout>
  )
}

export default BookNowPage