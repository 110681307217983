import React, { useState, useEffect, useRef } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Reaptcha from "reaptcha"
import axios from "axios"
import qs from "qs"

const ContactFormSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  last_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email_address: Yup.string()
    .email('Invalid Email')
    .required('Required'),
  phone_number: Yup.string()
    .required('Required'),
  subject: Yup.string()
    .required('Required'),
  message: Yup.string()
    .required('Required'),
});

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  required,
  ...props
}) => (
  <div className="col-span-6 sm:col-span-3">
    <label htmlFor={field.name} className="block text-sm font-medium leading-5 text-gray-700">{label} {required && <span className="text-red-600">*</span>}</label>
    <input id={field.name} {...field} {...props} required className={`mt-1 form-input block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${touched[field.name] && errors[field.name] ? `border-red-500` : `border-gray-300`}`} />
    {touched[field.name] &&
      errors[field.name] && <div className="text-xs text-red-600 mt-1">{errors[field.name]}</div>}
  </div>
);

const CustomSelectComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  required,
  label,
  children,
  ...props
}) => (
  <div className="col-span-6">
    <label htmlFor={field.name} className="block text-sm font-medium leading-5 text-gray-700">{label} {required && <span className="text-red-600">*</span>}</label>
    <select id={field.name} {...field} {...props} required className={`mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${touched[field.name] && errors[field.name] ? `border-red-500` : `border-gray-300`}`} >
      {children}
    </select>
    {touched[field.name] &&
      errors[field.name] && <div className="text-xs text-red-600 mt-1">{errors[field.name]}</div>}
  </div>
);

const CustomTextareaComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  required,
  label,
  children,
  subtext,
  ...props
}) => (
  <div className="col-span-6">
    <label htmlFor={field.name} className="block text-sm font-medium leading-5 text-gray-700">
      {label} {required && <span className="text-red-600">*</span>}
    </label>
    <div className="mt-1 rounded-md shadow-sm">
      <textarea id={field.name} {...field} {...props} required rows="5" className={`form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5  ${touched[field.name] && errors[field.name] ? `border-red-500` : `border-gray-300`}`}></textarea>
    </div>
    {subtext && <p className="mt-2 text-xs text-gray-500">{subtext}</p>}
    {touched[field.name] &&
      errors[field.name] && <div className="text-xs text-red-600 mt-1">{errors[field.name]}</div>}
  </div>
);

const ContactForm = () => {
  const [errMsg, setErrMsg] = useState("");
  const [executing, setExecuting] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formReset, setFormReset] = useState({});
  const [msgSent, setMsgSent] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [token, setToken] = useState("");
  const [verified, setVerified] = useState(false);

  const rcRef = useRef(null);

  useEffect(() => {
    console.log({isSubmitting, executing, verified});
  }, [isSubmitting, executing, verified])

  useEffect(() => {
    const handleSubmit = async (formValues, token) => {
      console.log('handleSubmit');
      const data = {
        ...formValues,
        "g-recaptcha-response": token
      };
      const options = {
        url: '/book-now',
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data)
      };
      try {
        await axios(options);
        setMsgSent(true);
        formReset();
      } catch (e) {
        setErrMsg(e.message);
      }
      setIsSubmitting(false)
    };
    if (token) {
      handleSubmit(formValues, token);
    }
  }, [formReset, formValues, token]);
  
  const onError = () => {
    console.log('onError');
    setErrMsg("Oops, something went wrong.")
  };

  const onExpire = () => {
    console.log('onExpire');
    resetReCaptcha();
  };

  const onLoad = resetForm => {
    console.log('onLoad');
    setFormReset(resetForm);
    setHasLoaded(true)
  };

  const onVerify = token => {
    console.log('onVerify');
    setToken(token);
    setVerified(true);
    setExecuting(false);
  };

  const resetReCaptcha = async () => {
    console.log('resetReCaptcha');
    try {
      await rcRef.current.reset();
      setVerified(false);
    } catch (e) {
      console.error(e);
    }
  };

  const removeError = () => {
    setErrMsg("")
  }
  const removeSuccess = () => {
    setMsgSent(false);
  }

  return (
    <div className="mt-10 xl:mx-12 relative lg:mt-0">
      <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
        <defs>
          <pattern id="svg-pattern-squares-2" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="784" height="404" fill="url(#svg-pattern-squares-2)" />
      </svg>
      <div className="relative mx-auto w-full rounded-md shadow-lg lg:max-w-lg">
        <Formik
          initialValues={{
            "bot-field": "",
            "form-name": "Contact Form",
            first_name: '',
            last_name: '',
            email_address: '',
            phone_number: '',
            subject: '',
            message: ''
          }}
          validationSchema={ContactFormSchema}
          onSubmit={values => {
            setIsSubmitting(true);
            setFormValues({ ...values });
            setExecuting(true);
            rcRef.current.execute();
          }}
        >
          {({ resetForm }) => {
            return (
              <Form
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                data-netlify-recaptcha="true"
                name="Contact Form"
                noValidate
              >
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="mb-2">
                      <h3 className="text-xl leading-6 font-semibold text-gray-900">
                        Quick Contact
                      </h3>
                      <div className="hidden sm:block">
                        <div className="py-5">
                          <div className="border-t border-gray-200"></div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                      <Field type="hidden" name="form-name" />
                      <Field type="hidden" name="bot-field" />

                      {errMsg ?
                        <div className="col-span-6 px-2 py-3 rounded-lg bg-red-600 shadow-lg">
                          <div className="flex items-center justify-between flex-wrap">
                            <div className="w-0 flex-1 flex items-center">
                              <p className="ml-3 font-medium text-white text-sm">
                                {errMsg}
                              </p>
                            </div>
                            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                              <button type="button" onClick={removeError} className="-mr-1 flex p-2 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-700 transition ease-in-out duration-150">
                                <svg className="h-5 w-5 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      : null}

                      {msgSent ?
                        <div className="col-span-6 px-2 py-3 rounded-lg bg-green-500 shadow-lg">
                          <div className="flex items-center justify-between flex-wrap">
                            <div className="w-0 flex-1 flex items-center">
                              <p className="ml-3 font-medium text-white text-sm">
                                Thank You! We'll respond to your query as soon as possible.
                              </p>
                            </div>
                            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                              <button type="button" onClick={removeSuccess} className="-mr-1 flex p-2 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600 transition ease-in-out duration-150">
                                <svg className="h-5 w-5 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      : null}

                      <Field type="text" name="first_name" component={CustomInputComponent} label="First name" aria-required="true" required />
                      <Field type="text" name="last_name" component={CustomInputComponent} label="Last name" aria-required="true" required />
                      <Field type="email" name="email_address" component={CustomInputComponent} label="Email address" aria-required="true" required />
                      <Field type="tel" name="phone_number" component={CustomInputComponent} label="Phone number" aria-required="true" required />

                      <Field name="subject" component={CustomSelectComponent} label="Subject" aria-required="true" required>
                        <option disabled value=""></option>
                        <option value="Airport Transfers">Airport Transfers</option>
                        <option value="Golf Trips">Golf Trips</option>
                        <option value="Cruise Ship Excursions">Cruise Ship Excursions</option>
                        <option value="Corporate Travel">Corporate Travel</option>
                        <option value="Wedding Transport">Wedding Transport</option>
                        <option value="Private Tours">Private Tours</option>
                        <option value="Other">Other</option>
                      </Field>

                      <Field name="message" component={CustomTextareaComponent} label="Message" subtext="Let us know as much information about your trip as possible." aria-required="true" required />

                      <Reaptcha
                        ref={rcRef}
                        sitekey="6LduTOUUAAAAAM80anLqqjzUOfeUJhEJno-hFVt4"
                        data-netlify-recaptcha="true"
                        onError={onError}
                        onExpire={onExpire}
                        onVerify={onVerify}
                        onLoad={() => onLoad(() => {
                          console.log('resetForm');
                          return resetForm
                        })}
                        size="invisible"
                        className="hidden"
                      />

                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-between">
                    <button type="reset" className="mr-2 py-2 px-4 border border-gray-200 text-sm leading-5 font-normal tracking-wider rounded-sm text-gray-900 uppercase bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:shadow-outline-blue active:bg-gray-50 transition duration-150 ease-in-out">Reset</button>
                    <button
                      className="py-2 px-4 border border-transparent text-sm leading-5 font-normal tracking-wider rounded-sm text-gray-900 uppercase bg-yellow-300 shadow-sm hover:bg-yellow-200 focus:outline-none focus:shadow-outline-blue active:bg-yellow-200 transition duration-150 ease-in-out disabled:cursor-not-allowed disabled:bg-yellow-300 disabled:opacity-50"
                      type="submit"
                      disabled={isSubmitting || executing || !hasLoaded}
                    >
                      {isSubmitting ? 'Sending...' : 'Send'}
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default ContactForm